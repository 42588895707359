import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'celphone' ]

  connect() {
    console.log('START CELPHONE')

    $(this.celphoneTarget).mask("(99) 99999-9999");
  }

}