import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'phone' ]

  connect() {
    console.log('START PHONE')

    $(this.phoneTarget).mask("(99) 9999-9999");
  }

}
