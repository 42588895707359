import 'controllers'
require("@rails/ujs").start()

import $ from 'jquery'
import 'jquery-mask-plugin'
import 'cocoon-js'
import 'select2'
import 'bootstrap'
import flatPickr from 'flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

import Turbolinks from 'turbolinks'
Turbolinks.start()
Turbolinks.setProgressBarDelay(250)

import 'stylesheets/application'