import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "q" ]

  connect() {
    console.log('START SEARCH')

    $(this.qTarget).typeahead({
      minLength: 3,
      order: "asc",
      hint: true,
      accent: true,
      dynamic: true,
      backdrop: {
        "background-color": "#3879d9",
        "opacity": "0.1",
        "filter": "alpha(opacity=10)"
      },
      source: {
        ajax: {
          url: '/users.json',
        }
      },
    })

  }
}
