import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'pass' ]

  initialize() {
    this.passwordResult = '#password-result'
    this.msg = ' \
          Sua senha precisa ter os seguintes requisitos: <br />\
          - pelo menos 6 caracteres <br />\
          - pelo menos uma letra minúscula <br />\
          - pelo menos uma letra maiúscula <br />\
          - pelo menos um número <br />\
          - pelo menos um dos seguintes caracteres: <br />\
            !@$%^&*()_+|~-=`{}[]:";\'?\, <br />\
            '
  }

  connect() {
    console.log('START PASSWORD')
  }

  checkStrength() {
    if (this.passTarget.value.length < 6) {
      $(this.passwordResult).removeClass()
      $(this.passwordResult).addClass('short')
      $(this.passwordResult).html('Senha muito curta. Mínimo 6 caracteres!')
      return false
    }

    const strength = this.calculateStrength(this.passTarget.value)

    if (strength <= 2) {
      $(this.passwordResult).removeClass()
      $(this.passwordResult).addClass('weak')
      $(this.passwordResult).html('Senha Fraca')
    } else {
      $(this.passwordResult).removeClass()
      $(this.passwordResult).addClass('strong')
      $(this.passwordResult).html('Senha Forte')
    }
  }

  getStrength() {
    const strength = this.calculateStrength(this.passTarget.value)

    $(this.passwordResult).removeClass()
    $(this.passwordResult).html('')

    if (strength <= 2) {
      $(this.passwordResult).addClass('weak')
      $(this.passwordResult).html(this.msg)
    } else {
      $(this.passwordResult).addClass('strong')
    }
  }

  calculateStrength(pass) {
    let strength = 0
    //if (pass.length > 7) strength += 1

    // If password contains both lower and uppercase characters, increase strength value.
    if (pass.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1

    // If it has numbers and characters, increase strength value.
    if (pass.match(/([a-zA-Z])/) && pass.match(/([0-9])/)) strength += 1

    // If it has one special character, increase strength value.
    if (pass.match(/([!,%,&,@,#,$,^,*,?,_,~,:,;,<,>,+,|,-,=])/)) strength += 1

    // If it has two special characters, increase strength value.
    //if (pass.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1

    return strength
  }
}
